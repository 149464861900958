import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './RichText.module.scss'

const RichText = ({
  className,
  children,
  headingStyles,
  listStyles,
  ...attributes
}) => {
  return (
    <div
      className={classNames(styles.el, className)}
      data-headings={headingStyles}
      data-lists={listStyles}
      {...attributes}
    >
      {children}
    </div>
  )
}

RichText.defaultProps = {
  className: undefined,
  headingStyles: true,
  listStyles: true,
}

RichText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  headingStyles: PropTypes.bool,
  listStyles: PropTypes.bool,
}

export { RichText }
