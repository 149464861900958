/**
 * Take into account mobile browser UI
 *
 * @example height: calc(var(--vh, 1vh) * 100);
 * @link https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */

import { useEffect, useRef, useState } from 'react'

export const useViewportHeight = (minHeightChange = 70, minWidthChange = 0) => {
  const [vh, setVh] = useState(null)
  const prevWindowSize = useRef({ height: 0, width: 0 })

  const setViewHeight = () => {
    const currentVh = window.innerHeight * 0.01
    setVh(currentVh)
    document.documentElement.style.setProperty('--vh', `${currentVh}px`)
  }

  useEffect(() => {
    const onResize = () => {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      const heightChange = Math.abs(
        windowHeight - prevWindowSize.current.height
      )
      const widthChange = Math.abs(windowWidth - prevWindowSize.current.width)

      // only update css var if height change is significant
      // this is to prevent issues when height changes
      // when bottom ui changes on scroll

      if (heightChange > minHeightChange || widthChange > minWidthChange) {
        setViewHeight()
        prevWindowSize.current = { height: windowHeight, width: windowWidth }
      }
    }
    setViewHeight()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [minHeightChange, minWidthChange])

  return vh
}
