// extracted by mini-css-extract-plugin
export var aniSpin = "Header-module--ani-spin--vsoXn";
export var black = "#000";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var logo = "Header-module--logo--7bOtT";
export var mediumDesktop = "1300px";
export var nav = "Header-module--nav--e5nQ0";
export var nav__btn = "Header-module--nav__btn--Cz69t";
export var nav__btn__inner = "Header-module--nav__btn__inner--7lNHe";
export var nav__item = "Header-module--nav__item--tjRn2";
export var nav__list = "Header-module--nav__list--O9mB7";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";