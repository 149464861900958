import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useMeasure } from 'react-use'
import { MailchimpForm } from '@/components/MailchimpForm'
import * as styles from './Subscribe.module.scss'

const Subscribe = ({ cta, submitLabel, url, fields, confirmation }) => {
  const [isVisible, setIsVisible] = useState(false)
  const handleClickToggle = () => setIsVisible((prev) => !prev)
  const [toggleRef, { width }] = useMeasure()
  const ref = useRef(null)

  useEffect(() => {
    // https://www.cluemediator.com/detect-click-outside-a-react-component-using-react-hooks
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsVisible(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  // Update hash
  useEffect(() => {
    if (isVisible) {
      console.log('UPDATE HASH')
      window.history.pushState(null, null, `#subscribe`)
    }
  }, [isVisible])

  return (
    <div className={styles.el} ref={ref}>
      <div className={styles.el__toggle} ref={toggleRef}>
        <button
          type="button"
          onClick={handleClickToggle}
          className={styles.el__toggle__button}
          data-color={isVisible ? 'inverse' : 'default'}
        >
          {cta} {isVisible ? '↩' : '→'}
        </button>
      </div>
      {isVisible && (
        <div className={styles.el__panel} style={{ minHeight: `${width}px` }}>
          <MailchimpForm
            submitLabel={submitLabel}
            url={url}
            fields={fields}
            successText={confirmation}
          />
        </div>
      )}
    </div>
  )
}

Subscribe.defaultProps = {
  cta: 'Subscribe',
  confirmation: 'Thank you for subscribing',
  submitLabel: 'Submit',
  url: undefined,
  fields: [],
}

Subscribe.propTypes = {
  cta: PropTypes.string,
  confirmation: PropTypes.node,
  submitLabel: PropTypes.string,
  url: PropTypes.string,
  fields: PropTypes.array,
}

export { Subscribe }
