import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SmoothScrollLink } from '@/components/SmoothScrollLink'
import * as styles from './Btn.module.scss'

const Btn = ({
  tag,
  className,
  innerClassName,
  children,
  isInverse,
  ...attributes
}) => {
  const renderInner = (
    <>
      <div className={classNames(styles.el__default, innerClassName)}>
        {children}
      </div>
      <div className={classNames(styles.el__hover, innerClassName)}>
        {children}
      </div>
    </>
  )

  if (tag) {
    const Tag = tag
    return (
      <Tag
        className={classNames(styles.el, className)}
        data-inverse={isInverse}
        {...attributes}
      >
        {renderInner}
      </Tag>
    )
  }

  return (
    <SmoothScrollLink
      className={classNames(styles.el, className)}
      data-inverse={isInverse}
      {...attributes}
    >
      {renderInner}
    </SmoothScrollLink>
  )
}

Btn.defaultProps = {
  tag: undefined,
  className: undefined,
  innerClassName: undefined,
  children: undefined,
  isInverse: false,
}

Btn.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.node,
  isInverse: PropTypes.bool,
}

export { Btn }
