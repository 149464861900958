import { useStaticQuery, graphql } from 'gatsby'
import { get } from '../../utils/get'
import { getLink } from '../utils/getLink'
import { getSlug } from '../../utils/getSlug'
import { getRichText } from '../utils/getRichText'

/**
 * Pull in Prismic config and fallback to Gatsby config
 */

export const useSiteConfig = () => {
  const data = useStaticQuery(
    graphql`
      query {
        gatsbyConfig: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        prismicConfig {
          data {
            title
            meta_description
            meta_subtitle
            meta_image {
              url
            }
            nav {
              title
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              hash
              type
            }
            newsletter_url
            newsletter_cta
            newsletter_email_label
            newsletter_button
            newsletter_confirmation {
              raw
            }
            footer_legal {
              raw
            }
          }
        }
      }
    `
  )

  const gatsbyConfig = data.gatsbyConfig.siteMetadata || {}
  const prismicConfig = data.prismicConfig.data || {}

  const title = prismicConfig.title || gatsbyConfig.title

  const nav = []
  if (prismicConfig.nav) {
    prismicConfig.nav.forEach((item, index) => {
      nav.push({
        ...getLink(item.link, item.title, `nav_${index}`),
        hash: getSlug(item.hash),
        type: getSlug(item.type),
      })
    })
  }

  return {
    metadata: {
      title,
      description: prismicConfig.meta_description || gatsbyConfig.description,
      subtitle: prismicConfig.meta_subtitle || gatsbyConfig.subtitle,
      image: get(prismicConfig, 'meta_image.url') || gatsbyConfig.image,
      siteUrl: gatsbyConfig.siteUrl,
    },
    header: {
      title,
      nav,
      newsletter: {
        cta: get(prismicConfig, 'newsletter_cta'),
        submitLabel: get(prismicConfig, 'newsletter_button'),
        url: get(prismicConfig, 'newsletter_url'),
        fields: [
          {
            id: 'mce-EMAIL',
            name: 'EMAIL',
            type: 'email',
            placeholder: get(prismicConfig, 'newsletter_email_label'),
            isRequired: true,
          },
        ],
        confirmation: getRichText(prismicConfig, 'newsletter_confirmation.raw'),
      },
    },
    footer: {
      legal: getRichText(prismicConfig, 'footer_legal.raw'),
    },
  }
}
