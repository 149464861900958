// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-prismic-types-menu-prismic-jsx": () => import("./../../../src/prismic/types/Menu.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-menu-prismic-jsx" */),
  "component---src-prismic-types-page-prismic-jsx": () => import("./../../../src/prismic/types/Page.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-page-prismic-jsx" */)
}

