// extracted by mini-css-extract-plugin
export var aniSpin = "MailchimpForm-module--ani-spin--pgQTT";
export var black = "#000";
export var btn = "MailchimpForm-module--btn--iB+Bu";
export var field = "MailchimpForm-module--field--WvygX";
export var field__input = "MailchimpForm-module--field__input--opXoc";
export var inner = "MailchimpForm-module--inner--lZcfp";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var mediumDesktop = "1300px";
export var messages = "MailchimpForm-module--messages--JZLOb";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var wrap = "MailchimpForm-module--wrap--VMO4y";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";