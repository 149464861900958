import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { ScrollContext } from '@/components/SmoothScroll'
import variants from './StickyHeader.motion'
import * as styles from './StickyHeader.module.scss'

const IS_BROWSER = typeof window !== 'undefined'

const StickyHeader = ({ children }) => {
  const [position, setPosition] = useState('visible')
  const { smoothScroll } = useContext(ScrollContext)
  const scrollThreshold = useRef(1000)

  const onResize = () => {
    const firstSection = document.querySelector('[data-section]')
    if (firstSection) {
      scrollThreshold.current = Math.max(firstSection.offsetHeight - 200, 0)
    }
  }

  useEffect(() => {
    if (!IS_BROWSER) return null

    onResize()

    if (smoothScroll) {
      smoothScroll.on('scroll', (instance) => {
        if (instance.scroll.y < scrollThreshold.current) {
          setPosition('visible')
        } else {
          setPosition('hidden')
        }
      })
    }
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [smoothScroll])

  return (
    <motion.div
      className={styles.stickyHeader}
      variants={variants.main}
      initial="visible"
      animate={position}
    >
      {children}
    </motion.div>
  )
}

StickyHeader.defaultProps = {
  children: undefined,
}

StickyHeader.propTypes = {
  children: PropTypes.node,
}

export { StickyHeader }
