/**
 * Get Prismic Link Data
 * @param {Object} data
 * @param {String} title
 * @param {String} id
 */

import { get } from '../../utils/get'
import { linkResolver } from './linkResolver'

export const getLink = (data, title = 'Read more', id = 'link') => {
  const linkType = get(data, 'link_type')

  switch (linkType) {
    case 'Document': {
      const document = get(data, 'document')
      const documentParent = get(data, 'document.data.parent.document')
      return {
        id,
        title,
        url: documentParent
          ? `${linkResolver(documentParent)}/${document.uid}`
          : linkResolver(document),
      }
    }
    case 'Web': {
      return {
        id,
        title,
        url: data.url,
        target: data.target,
      }
    }
    default: {
      return {
        id,
        title,
        url: data.url,
      }
    }
  }
}
