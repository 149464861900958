import React from 'react'
import PropTypes from 'prop-types'
import { PreviewStoreProvider } from 'gatsby-source-prismic'
import Layout from '../../layout'
import { useSiteConfig } from '../hooks/useSiteConfig'

/**
 * Prismic Layout is a pre-wrap which pulls in any
 * prismic content to pass to the main Layout component
 */

const LayoutPrismic = ({ path, pageContext, children, location }) => {
  const site = useSiteConfig()

  const page = {
    title: pageContext.title || '',
    path,
    image: pageContext.image,
  }

  return (
    <PreviewStoreProvider>
      <Layout site={site} page={page} location={location}>
        {children}
      </Layout>
    </PreviewStoreProvider>
  )
}

LayoutPrismic.defaultProps = {
  children: undefined,
  path: '',
}

LayoutPrismic.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default LayoutPrismic
