import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Btn } from '@/components/Btn'
import { checkValidEmail } from '@/utils/checkValidEmail'
import * as styles from './MailchimpForm.module.scss'

const MailchimpForm = ({
  submitLabel,
  url,
  fields,
  onSuccess,
  successText,
}) => {
  const [inputs, setInputs] = useState({})
  const [errors, setErrors] = useState([])

  const handleSubmit = (event, subscribe) => {
    if (event) {
      event.preventDefault()
      const errorMsgs = []

      fields.forEach((field) => {
        if (
          (field.isRequired && field.type === 'email' && !inputs[field.name]) ||
          (field.isRequired && field.type === 'text' && !inputs[field.name])
        ) {
          errorMsgs.push('Please fill out required fields.')
        } else if (
          field.type === 'email' &&
          !checkValidEmail(inputs[field.name])
        ) {
          errorMsgs.push('Your email address is invalid.')
        }
      })

      if (!errorMsgs.length) {
        subscribe(inputs)
        onSuccess()
      } else {
        setErrors(errorMsgs)
      }
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    setErrors([])
    setInputs((i) => ({
      ...i,
      [event.target.name]: event.target.value,
    }))
  }

  const removeDuplicates = (array) => {
    return array.filter((a, b) => array.indexOf(a) === b)
  }

  return (
    <div>
      {url && (
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => {
            return (
              <div className={styles.inner}>
                <div className={styles.messages}>
                  {status === 'sending' && (
                    <div data-type="sending">Sending...</div>
                  )}
                  {status === 'error' && (
                    <div data-type="error">
                      {typeof message === 'string'
                        ? message
                        : 'Error submitting, please try again'}
                    </div>
                  )}
                  {status === 'success' && (
                    <div data-type="success">{successText}</div>
                  )}
                  {removeDuplicates(errors)
                    .reverse()
                    .map((error, index) => {
                      return (
                        <div key={`${index}-error-${error}`} data-type="error">
                          • {error}
                        </div>
                      )
                    })}
                </div>
                <div className={styles.wrap}>
                  <div>
                    {fields.map((item) => {
                      if (
                        item.type === 'text' ||
                        item.type === 'email' ||
                        item.type === 'tel'
                      ) {
                        return (
                          <div className={styles.field} key={item.id}>
                            <input
                              id={item.id}
                              name={item.name}
                              type={item.type}
                              placeholder={item.placeholder}
                              onChange={handleInputChange}
                              className={styles.field__input}
                            />
                          </div>
                        )
                      }
                      return undefined
                    })}
                  </div>
                  <div>
                    <Btn
                      tag="button"
                      type="submit"
                      onClick={(event) => handleSubmit(event, subscribe)}
                      className={styles.btn}
                      isInverse
                    >
                      {submitLabel}
                    </Btn>
                  </div>
                </div>
              </div>
            )
          }}
        />
      )}
    </div>
  )
}

MailchimpForm.defaultProps = {
  submitLabel: 'Submit',
  fields: [],
  onSuccess: () => {},
  successText: 'Thank you',
}

MailchimpForm.propTypes = {
  submitLabel: PropTypes.string,
  url: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.node,
      isRequired: PropTypes.bool,
    })
  ),
  onSuccess: PropTypes.func,
  successText: PropTypes.node,
}

export { MailchimpForm }
