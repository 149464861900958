import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import { ScrollContext } from '@/components/SmoothScroll'

// https://chaseohlson.com/gatsby-link-anchor-navigation

const IS_BROWSER = typeof window !== 'undefined'

const SmoothScrollLink = ({ to, className, children, ...attributes }) => {
  const { smoothScroll } = useContext(ScrollContext)

  const handleMenuLinkClick = (event) => {
    if (IS_BROWSER && to.includes('#')) {
      const [anchorPath, anchor] = to.split('#')
      if (window.location.pathname === anchorPath) {
        event.preventDefault()
        const element = document.getElementById(anchor)

        smoothScroll.scroll.scrollTo(element)
      }
    }
  }

  return (
    <Link
      to={to}
      onClick={handleMenuLinkClick}
      className={className}
      {...attributes}
    >
      {children}
    </Link>
  )
}

SmoothScrollLink.defaultProps = {
  to: undefined,
  className: '',
}

SmoothScrollLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export { SmoothScrollLink }
