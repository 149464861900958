/**
 * convert string to slug
 * @param {String} text
 * @param {Object} options
 */

import slugify from 'slugify'

export const getSlug = (text, options) => {
  if (typeof text === 'string') {
    return slugify(text, { lower: true, ...options })
  }

  return undefined
}
