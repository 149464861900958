import React, { useEffect, useRef, createContext, useState } from 'react'
import PropTypes from 'prop-types'
import LocomotiveScroll from 'locomotive-scroll' // exluded from loading at build time in gatsby-node.js
import useResizeObserver from 'use-resize-observer'
import { globalHistory } from '@reach/router'
import './SmoothScroll.scss'

// https://github.com/fcisio/gatsby-locomotivescroll-starter
// https://github.com/locomotivemtl/locomotive-scroll/issues/13

const ScrollContext = createContext()

const SmoothScroll = ({ children, containerRef }) => {
  const path = globalHistory.location.pathname
  const controllerRef = useRef(null)
  const { height } = useResizeObserver({ ref: containerRef })
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    controllerRef.current = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      smoothMobile: false,
      touchMultiplier: 2.5,
      lerp: 0.15,
    })
    setIsReady(true) // Re-render the context

    return () => {
      if (controllerRef.current) {
        controllerRef.current.destroy()
      }
      setIsReady(false)
    }
  }, [path])

  useEffect(() => {
    if (controllerRef.current) {
      controllerRef.current.update()
    }
  }, [height])

  return (
    <ScrollContext.Provider
      value={{ smoothScroll: controllerRef.current, isReady }}
    >
      {children}
    </ScrollContext.Provider>
  )
}

SmoothScroll.propTypes = {
  children: PropTypes.node.isRequired,
  containerRef: PropTypes.object.isRequired,
}

export { SmoothScroll, ScrollContext }
