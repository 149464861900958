import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Link } from '@/components/Link'
import { RichText } from '@/components/RichText'
import * as styles from './Footer.module.scss'

const Footer = ({ legal }) => {
  return (
    <footer className={styles.el}>
      <Container>
        <div className={styles.el__inner}>
          {legal && (
            <div className={styles.el__column}>
              <RichText>{legal}</RichText>
            </div>
          )}
          <div className={styles.el__column}>
            <div className={styles.el__credits}>
              <RichText className={styles.el__credits__item}>
                DESIGN{' '}
                <Link to="http://tomclayton.co" target="_blank">
                  Tom Clayton
                </Link>
              </RichText>
              <RichText className={styles.el__credits__item}>
                BUILD{' '}
                <Link to="https://www.standandmarvel.com" target="_blank">
                  Stand and Marvel
                </Link>
              </RichText>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

Footer.defaultProps = {
  legal: undefined,
}

Footer.propTypes = {
  legal: PropTypes.node,
}

export { Footer }
