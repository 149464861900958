import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import '../styles/main.scss'
import { Metadata } from '../components/Metadata'
import { FontFaceLoader } from '../components/FontFaceLoader'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { SmoothScroll } from '../components/SmoothScroll'
import { useViewportHeight } from '../hooks/useViewportHeight'
import * as styles from './Layout.module.scss'
import favicon from '../images/favicon.png'
import faviconDarkmode from '../images/favicon-darkmode.png'

const Layout = ({ site, page, children }) => {
  useViewportHeight()
  const containerRef = useRef(null)

  return (
    <>
      <Metadata
        title={page.title}
        defaultTitle={
          site.metadata.subtitle
            ? `${site.metadata.title}: ${site.metadata.subtitle}`
            : site.metadata.title
        }
        titleTemplate={`%s — ${site.metadata.title}`}
        favicon={{ default: favicon, darkMode: faviconDarkmode }}
        description={site.metadata.description}
        image={page.image || site.metadata.image}
        siteUrl={site.metadata.siteUrl}
        path={page.path}
      />
      <FontFaceLoader
        fonts={['GT America Standard', 'Gopher-Bold']}
        renderLoader={<div className={styles.loader} />}
      >
        <div className={styles.layout}>
          <SmoothScroll containerRef={containerRef}>
            <Header {...site.header} />
            <div className={styles.scroll} data-scroll-container>
              <main className={styles.main}>{children}</main>
              <Footer {...site.footer} />
            </div>
          </SmoothScroll>
        </div>
      </FontFaceLoader>
    </>
  )
}

Layout.defaultProps = {
  site: {
    metadata: {},
    favicon: undefined,
    header: {},
    footer: {},
  },
  page: {
    title: undefined,
    image: undefined,
    path: undefined,
  },
}

Layout.propTypes = {
  site: PropTypes.shape({
    metadata: PropTypes.object,
    favicon: PropTypes.string,
    header: PropTypes.object,
    footer: PropTypes.object,
  }),
  page: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    path: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
