/**
 * get: an alternative to lodash.get()
 * @author @harish2704, @muffypl, @pi0, @imnnquy
 * @param {Object} object
 * @param {String|Array} path
 * @param {*} defaultVal
 */

function getProp(object, path, defaultVal) {
  const PATH = Array.isArray(path)
    ? path
    : path.split('.').filter((i) => i.length)
  if (!PATH.length) {
    return object === undefined ? defaultVal : object
  }
  if (
    object === null ||
    object === undefined ||
    typeof object[PATH[0]] === 'undefined'
  ) {
    return defaultVal
  }
  return getProp(object[PATH.shift()], PATH, defaultVal)
}

exports.get = getProp
