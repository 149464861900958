import React from 'react'
import PropTypes from 'prop-types'
import { SmoothScrollLink } from '@/components/SmoothScrollLink'
import { Link } from '@/components/Link'
import { Subscribe } from '@/components/Subscribe'
import { Btn } from '@/components/Btn'
import { StickyHeader } from '@/components/StickyHeader'
import LogoSvg from './images/logo.inline.svg'
import * as styles from './Header.module.scss'

const Header = ({ title, nav, newsletter }) => {
  return (
    <header>
      <StickyHeader>
        <h1 className={styles.logo}>
          <Link to="/" aria-label={title}>
            <LogoSvg />
          </Link>
        </h1>
      </StickyHeader>
      <div className={styles.nav}>
        <ul className={styles.nav__list}>
          {nav.map((item) => {
            const url = item.hash ? `${item.url}#${item.hash}` : item.url
            return (
              <li key={item.id} className={styles.nav__item}>
                {item.type === 'button' ? (
                  <Btn
                    to={url}
                    className={styles.nav__btn}
                    innerClassName={styles.nav__btn__inner}
                  >
                    {item.title}
                  </Btn>
                ) : (
                  <SmoothScrollLink to={url}>{item.title}</SmoothScrollLink>
                )}
              </li>
            )
          })}
        </ul>
      </div>
      {newsletter.url && <Subscribe {...newsletter} />}
    </header>
  )
}

Header.defaultProps = {
  title: undefined,
  nav: [],
  newsletter: {},
}

Header.propTypes = {
  title: PropTypes.string,
  nav: PropTypes.array,
  newsletter: PropTypes.shape({
    cta: PropTypes.string,
    submitLabel: PropTypes.string,
    url: PropTypes.string,
  }),
}

export { Header }
