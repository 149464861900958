const variants = {
  main: {
    hidden: {
      y: '-100%',
      transition: {
        ease: 'easeIn',
        duration: 0.3,
      },
    },
    visible: {
      y: '0%',
      transition: {
        // ease: [0.165, 0.84, 0.44, 1],
        ease: 'easeOut',
        duration: 0.3,
      },
    },
  },
}

export default variants
