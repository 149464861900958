// extracted by mini-css-extract-plugin
export var aniSpin = "Layout-module--ani-spin--Z9K8s";
export var black = "#000";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var layout = "Layout-module--layout--Xc7Ik";
export var loader = "Layout-module--loader--2vKZT";
export var main = "Layout-module--main--Cqm9K";
export var mediumDesktop = "1300px";
export var scroll = "Layout-module--scroll--dxI0h";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";